import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentData } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { forkJoin, from, Observable, of, zip } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { FileUpload } from '../../shared/models/file-upload.model';
import { FilterModel } from '../../shared/models/filter.model';
import { BaseService } from '../abstractions/base-service';
import { HttpClient } from '@angular/common/http';
import { FireLoggingService } from './fire-logging.service';
import { Functions } from '@angular/fire/functions';
@Injectable({
    providedIn: 'root',
})
export class FirestoreService extends BaseService<any> {
    constructor(private storage: AngularFireStorage,
                protected firestore: AngularFirestore,
                protected http: HttpClient,
                protected functions: Functions,
                protected fireLogging: FireLoggingService) {
        super(firestore, http, functions, fireLogging);
    }

    /**
     * Upload single file into Firestorage.
     * @param fileUpload = { file: File }
     * @param path The desired path to upload the file
     * @returns Observable of FileUpload
     */
    uploadFile(
        fileUpload: FileUpload,
        path: string,
        uniqueFileName = true
    ): Observable<{ progress: number; path: string; url: string }> {
        const storageRef = this.storage.storage.ref();
        const fileName = uniqueFileName ? `${Date.now()}_${fileUpload.file.name}` : `${fileUpload.file.name}`;
        return from(
            storageRef
                .child(`${path}/${fileName}`)
                .put(fileUpload.file)
        ).pipe(
            concatMap((res) => {
                return from(res.ref.getDownloadURL()).pipe(
                    map((url) => ({ url, ...res }))
                );
            }),
            map((res) => {
                return {
                    progress: 100,
                    path: `${path}/${fileUpload.file.name}`,
                    url: res.url,
                };
            })
        );
    }

    /**
     * Get the files present in Firestorage through the indicated path.
     * @param path The desired path
     * @returns Observable
     */
    getFiles(path: string): Observable<any> {
        const listRef = this.storage.ref(path);
        return listRef.listAll().pipe(
            switchMap((result) => {
                const fileData = result?.items?.map((item) =>
                    from(item.getMetadata())
                    .pipe(
                        map((metadata) => ({
                            name: item.name,
                            size: metadata.size,
                            type: metadata.contentType,
                            lastModified: metadata.timeCreated,
                            url: '', // Placeholder for the download URL
                        })),
                        concatMap((data) => {
                            return from(item.getDownloadURL())
                                .pipe(
                                    map((res) => {
                                        const fileUpload = new FileUpload();
                                        const file = new File([], item.name, {
                                            type: data.type,
                                        });

                                        fileUpload.file = file;
                                        fileUpload.path = item.fullPath;
                                        fileUpload.url = res;
                                        return fileUpload;
                                    })
                                )
                            }
                        )
                    )
                );
                return forkJoin(fileData);
            }),
        );
    }

    /**
     * Get the file url from the indicated path.
     * @param path The desired path
     * @returns Promise<string>
     */
    async getFile(path: string): Promise<string> {
        return await this.storage.storage.ref().child(path)
            .getDownloadURL().then((url) => url);
    }

    deleteFile(path: string): Observable<any> {
        const storageRef = this.storage.ref(path);
        return storageRef.delete();
    }

    deleteFolder(path: string) {
        const storageRef = this.storage.ref(path);
        return storageRef.listAll().pipe(
            concatMap((files) =>
                zip(...files.items.map((dir) => from(dir.delete())))
            )
        );
    }

    getAllProducts(url: string, filter: FilterModel) {
        return this.search(url, filter).pipe(
            concatMap((items: Array<any>) => {
                if (items?.length) {
                    return zip(...items.map(this.handleProductData));
                }
                return of([]);
            })
        );
    }

    createDocumentReferenceId(collection: string): string {
        return this.firestore.collection(collection).doc().ref.id;
    }

    private handleProductData(item) {
        return from(item.basicInfo.get()).pipe(
            map((basicInfo: DocumentData) => {
                return { ...basicInfo.data(), ...item };
            })
        );
    }

    downloadFile(path: string) {
        const fileRef = this.storage.ref(path);
        return fileRef.getDownloadURL()
        .pipe(
            catchError((error) => {
                throw of(error);
            })
        );
    }
}
